import React from "react"

import { Layout } from "../components"

const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <div className="container">
        <h1>Not Found</h1>
        <p>このページは見つかりませんでした。</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage